import { useRef, useEffect, useState } from "react";
import PropTypes from "prop-types";

import { tagular } from "@cohesion/tagular";

import { Button } from "@components/button";

import { useIntersectionObserver } from "@utils/useIntersectionObserver";

export function BITPromo({ hero }) {
  const video = useRef();
  const [firstLoad, setFirstLoad] = useState(true);
  const { setNodeRef, isVisible } = useIntersectionObserver(
    {
      threshold: 0.5,
    },
    false
  );

  useEffect(() => {
    // Attempt to autoplay the video
    if (video?.current && isVisible && firstLoad) {
      const playPromise = video.current.play();

      if (playPromise !== undefined) {
        playPromise.catch(error => {
          // eslint-disable-next-line no-console
          console.warn("Video autoplay error", error);
        });
      }
    }
    if (isVisible && firstLoad) {
      tagular("view", {
        webElement: {
          location: "SECTION",
          elementType: "BUTTON",
          position: "BIT",
          text: "DISCOVERTHEWINNERS",
        },
      });

      setFirstLoad(false);
    }
  }, [firstLoad, isVisible]);
  return (
    <section
      className="relative w-full py-40 md:pt-112 md:pb-80"
      ref={setNodeRef}
    >
      <video
        autoPlay
        loop
        id="expert-video"
        muted
        playsInline
        className="absolute top-0 left-0 z-0 object-cover w-full h-full"
        ref={video}
        poster={hero.poster}
      >
        <source src={hero.url} type="video/mp4" />
        <track kind="captions" />
      </video>
      <div className="absolute top-0 w-full h-full bg-black/30" />
      <div className="absolute bottom-0 w-full h-96 bg-gradient-to-t from-black" />
      <div className="relative">
        <h1 className="px-4 text-white font-tusker text-[100px] md:text-[134px] leading-[1.05] text-center uppercase mb-12">
          Best in travel 2024
        </h1>
        <div className="z-10 flex justify-center text-center ">
          <div>
            <Button
              className="flex-shrink-0 block mb-6 btn-solid w-content"
              href="/best-in-travel"
              onClick={() => {
                tagular("click", {
                  actionOutcome: "INTERNALLINK",
                  outboundUrl: "/best-in-travel",
                  webElement: {
                    location: "SECTION",
                    elementType: "BUTTON",
                    position: "BIT",
                    text: "DISCOVERTHEWINNERS",
                  },
                });
              }}
            >
              Discover the winners
            </Button>
          </div>
        </div>
      </div>
    </section>
  );
}

BITPromo.propTypes = {
  hero: PropTypes.shape({
    poster: PropTypes.string,
    url: PropTypes.string.isRequired,
  }).isRequired,
};
